<template>
<div id="contentwrap">
  <PageTitle />
  <article class="content-section">
    <div class="inner">
      <header>
        <h1 class="title">Press</h1>
      </header>
      <br><br>
      <img src="/images/press/Top100-Production-Q1-2024.webp" alt="Top 100 Productions" class="pdf-image"><br><br>
      <p>The Meredith Schlosser Team is honored to be recognized by Berkshire Hathaway HomeServices California Properties among their Top 100 for January–December 2024, ranking 
        <a target="_blank" href="/images/press/production-january-december-2024.pdf" style="font-weight:bold;text-decoration:underline;">#13 in Production</a> 
        and <a target="_blank" href="/images/press/transactions-january-december-2024.pdf" style="font-weight:bold;text-decoration:underline;">#4 in Transactions</a> for the Los Angeles, Ventura, Santa Barbara & San Luis Obispo region.
      </p>
      <hr>
      <br><br>
      <img src="/images/press/bhhs.webp" alt="Top 100 Transactions" class="pdf-image"><br><br>
      <p>
        The Meredith Schlosser Team is honored to be recognized nationwide in the Berkshire Hathaway HomeServices Quarterly Spotlight, ranking #10 among the Top 10 Small Teams in Total Production for Q3 2024
        <a target="_blank" href="https://www.instagram.com/reel/DCpoO9IB0sC" style="font-weight:bold;text-decoration:underline;">Learn more</a>
      </p>
      <hr>
      <br><br>
      <img src="/images/press/top-10-sept-2024.webp" alt="Top 100 Transactions" class="pdf-image"><br><br>
      <p>We are thrilled to announce that the Meredith Schlosser Team has earned the #9 ranking in Berkshire Hathaway HomeServices’ September Top 10 Transactions, showcasing their exceptional performance in just one month.</p>
      <hr>
      <br><br>
      <a href="/images/press/Top100-Transactions-Q2-2024.pdf" target="_blank"><img src="/images/press/Top100-Transactions-Q2-2024.webp" alt="Top 100 Transactions" class="pdf-image"></a><br><br>
      <p>The Meredith Schlosser Team achieved an impressive #6 ranking in Berkshire Hathaway HomeServices’ Top 100 for Los Angeles, Ventura, and Santa Barbara based on transactions in Q2 2024.
        <a target="_blank" href="/images/press/Top100-Transactions-Q2-2024.pdf" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <a href="/images/press/Top100-Production-Q2-2024.pdf" target="_blank"><img src="/images/press/Top100-Production-Q2-2024.webp" alt="Top 100 Productions" class="pdf-image"></a><br><br>
      <p>Ranking #16 for production in Q2 2024, the Meredith Schlosser Team was recognized in Berkshire Hathaway HomeServices’ Top 100 for Los Angeles, Ventura, and Santa Barbara.
        <a target="_blank" href="/images/press/Top100-Production-Q2-2024.pdf" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <a href="/images/press/leaders-of-influence-2024.pdf" target="_blank"><img src="/images/los-angeles-business-journal.svg" alt="Press los angeles business journal" width="280"></a><br><br>
      <p>Meredith Schlosser Recognized as a 2024 Leader of Influence in Residential Real Estate by the Los Angeles Business Journal.
        <a target="_blank" href="/images/press/leaders-of-influence-2024.pdf" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <img src="/images/press-bhhs-go-live.webp" alt="Press los angeles business journal" class="image-style-1"><br><br>
      <p>Meredith Schlosser shares insights on unlocking success in real estate during her exclusive interview on Berkshire Hathaway HomeServices' GO LIVE.</p>
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/1010795860?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="800" height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br><br>
      <a href="https://labusinessjournal.com/custom-content/leaders-of-influence-residential-real-estate-brokers-2023-meredith-schlosser/" target="_blank"><img src="/images/los-angeles-business-journal.svg" alt="Press los angeles business journal" width="280"></a><br><br>
      <p>Leaders of Influence: Residential Real Estate Brokers 2023 - Meredith Schlosser
        <a target="_blank" href="https://labusinessjournal.com/custom-content/leaders-of-influence-residential-real-estate-brokers-2023-meredith-schlosser/" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <a href="https://robbreport.com/shelter/celebrity-homes/cheryl-burke-house-hollywood-hills-1234881026/" target="_blank" alt="Press Real"><img src="/images/press-robb.svg" alt="Press Real" width="280"></a><br><br>
      <p>After 14 Years, Cheryl Burke Sells Her Midcentury L.A. Home
        <a target="_blank" href="https://robbreport.com/shelter/celebrity-homes/cheryl-burke-house-hollywood-hills-1234881026/" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <a href="https://www.rismedia.com/2023/08/22/bhhs-california-properties-agents-recognized-los-angeles-business-journal/" target="_blank"><img src="/images/rismedia.png" alt="Press los angeles business journal" width="180"></a><br><br>
      <p>BHHS California Properties Agents Recognized In Los Angeles Business Journal
        <a target="_blank" href="https://www.rismedia.com/2023/08/22/bhhs-california-properties-agents-recognized-los-angeles-business-journal/" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <a href="https://www.globenewswire.com/news-release/2023/01/10/2586644/0/en/53-Berkshire-Hathaway-HomeServices-California-Properties-Los-Angeles-And-Santa-Barbara-Agents-and-Teams-Named-Among-2023-Real-Estate-All-Stars.html" target="_blank" alt="Press Real"><img src="/images/press-sep-2022.png" alt="Press Real" width="280"></a><br><br>
      <p>Meredith Schlosser Team Named One of Los Angeles Magazine's 2023 Real Estate All Stars.
        <a target="_blank" href="https://www.globenewswire.com/news-release/2023/01/10/2586644/0/en/53-Berkshire-Hathaway-HomeServices-California-Properties-Los-Angeles-And-Santa-Barbara-Agents-and-Teams-Named-Among-2023-Real-Estate-All-Stars.html" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br><br>
      <a href="https://www.realtrends.com/rankings/real-trends-americas-best-teams-by-volume-2022/" target="_blank" alt="Press Real"><img src="/images/press-real-trends.png" alt="Press Real" width="280"></a><br><br>
      <p>Meredith Schlosser Team Ranked Among the Top 1.5% of 1.6 Million Real Estate Professionals in the United States.
        <a target="_blank" href="https://www.realtrends.com/rankings/real-trends-americas-best-teams-by-volume-2022/" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br>
      <br><br>
      <a href="https://www.realtrends.com/rankings/real-trends-americas-best-teams-by-volume-2022/" target="_blank" alt="Press Real"><img src="/images/press-mortgagevoice.png" alt="Press Real" width="280"></a><br><br>
      <p>Meredith Schlosser featured on the Mortgage Voice discussing the current real estate market in September 2022.
        <a target="_blank" href="https://themortgagevoice.com/portfolio-item/meredith-schlosser-2/" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br>
      <a href="https://www.mansionglobal.com/listings-articles/los-angeles-california-home-with-2-660-square-feet-and-three-bedrooms-asks-1-9-million-01654693347" target="_blank" alt="Yahoo"><img src="/images/press-mansion-global.jpeg" alt="Press Mansion" width="280"></a><br><br>
      <p>Meredith Schlosser's One-of-a-Kind Playa Vista Listing Featured in Mansion Global.  
        <a target="_blank" href="https://www.mansionglobal.com/listings-articles/los-angeles-california-home-with-2-660-square-feet-and-three-bedrooms-asks-1-9-million-01654693347" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br>
      <a href="https://finance.yahoo.com/news/meredith-schlosser-doubles-her-sales-181100178.html?guccounter=1&guce_referrer=aHR0cHM6Ly90cmVsbG8uY29tLw&guce_referrer_sig=AQAAAHIIJLGSrCDszYxYokcpPwJpPDzk-JkeOiVtCIGJm2PeYv8wSx2unCHy3QKZCLDj-sYjLczGGYeXobIlqM2PQ2WLY7YLxiv4wVyti4yD_8KaoMlbPCkwMfkR38rKKsut9UgIQtOr965I24tMiLQep7gdcdXAgrxpt47GukrGMbRe" target="_blank" alt="Yahoo"><img src="/images/press-yahoo.png" alt="Yahoo" width="280"></a><br><br>
      <p>Meredith Schlosser Doubles Her Sales to $100 Million+ After Joining Berkshire Hathaway HomeServices California Properties. 
        <a target="_blank" href="https://finance.yahoo.com/news/meredith-schlosser-doubles-her-sales-181100178.html?guccounter=1&guce_referrer=aHR0cHM6Ly90cmVsbG8uY29tLw&guce_referrer_sig=AQAAAHIIJLGSrCDszYxYokcpPwJpPDzk-JkeOiVtCIGJm2PeYv8wSx2unCHy3QKZCLDj-sYjLczGGYeXobIlqM2PQ2WLY7YLxiv4wVyti4yD_8KaoMlbPCkwMfkR38rKKsut9UgIQtOr965I24tMiLQep7gdcdXAgrxpt47GukrGMbRe" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br>
      <a href="https://www.globenewswire.com/news-release/2021/12/16/2354007/0/en/Meredith-Schlosser-Doubles-Her-Sales-to-100-Million-After-Joining-Berkshire-Hathaway-HomeServices-California-Properties.html" target="_blank" alt="NewsWires"><img src="/images/press-newswire.svg" alt="NewsWires" width="280"></a><br><br>
      <p>Meredith Schlosser Doubles Her Sales to $100 Million+ After Joining Berkshire Hathaway HomeServices California Properties. 
        <a target="_blank" href="https://www.globenewswire.com/news-release/2021/12/16/2354007/0/en/Meredith-Schlosser-Doubles-Her-Sales-to-100-Million-After-Joining-Berkshire-Hathaway-HomeServices-California-Properties.html" style="font-weight:bold;text-decoration:underline;">Full article</a>
      </p>
      <hr>
      <br>
      <a href="https://www.realtor.com/advice/buy/bidding-war-mistakes-homebuyers-are-making-today/" target="_blank" alt="NewsWires"><img src="/images/press-rdc-logo-default.svg" alt="NewsWires" width="280"></a><br><br>
      <p> Meredith Schlosser interviewed by 
        <a target="_blank" href="https://www.realtor.com/advice/buy/bidding-war-mistakes-homebuyers-are-making-today/" style="font-weight:bold;text-decoration:underline;">realtor.com</a>
        on the most common bidding war mistakes
      </p>
      <hr>
      <br>
      <p>First Time Buyer/Seller Q&A with Katrina Malaiba & Meredith Schlosser</p>
      <br>
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/677883435" width="800" height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p>Meredith Schlosser featured on the Mortgage Voice discussing the current real estate market in January 2022.</p>
      <div class="video-container">
        <iframe src="https://www.youtube.com/embed/T6NkHM3oiV0?start=1934" width="800"
          height="450" frameborder="0" webkitallowfullscreen=""
          mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p>
        <a href="https://rismedia.com/2021/01/19/silicon-beach-team-affiliates-with-berkshire-hathaway-homeservices-california-properties/" target="_blank" aria-label="Playa Vista Direct">
          <img src="/images/press-ris.png" alt="RIS Media" width="220">
        </a>
      </p>
      <p>
        Meredith Schlosser Team featured in 
        <a href="https://rismedia.com/2021/01/19/silicon-beach-team-affiliates-with-berkshire-hathaway-homeservices-california-properties/" target="_&quot;blank&quot;"
          style="font-weight:bold;text-decoration:underline;">RIS Media</a>.
      </p>
      <hr>
      <br>
      <p>Meredith Schlosser featured on the Mortgage Voice discussing the start of her own
        team.
      </p>
      <div class="video-container">
        <iframe src="https://www.youtube.com/embed/dQQ0H7SG6ko?start=1338" width="800"
          height="450" frameborder="0" webkitallowfullscreen=""
          mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p><a href="https://playavistadirect.com/playa-vista-power-couple-smash-trifecta-multitasking/"
        target="_blank" alt="Playa Vista Direct"><img
        src="/images/press-playa-vista-direct.png"
        alt="Playa Vista Direct" width="270"></a></p>
      <p><a href="https://playavistadirect.com/playa-vista-power-couple-smash-trifecta-multitasking/"
        target="_blank" alt="Playa Vista Direct"></a>Meredith Schlosser was
        featured in <a
          href="https://playavistadirect.com/playa-vista-power-couple-smash-trifecta-multitasking/"
          target="_&quot;blank&quot;"
          style="font-weight:bold;text-decoration:underline;">Playa Vista
        Direct</a>!
      </p>
      <hr>
      <br>
      <p><a href="https://www.latimes.com/business/real-estate/story/2020-01-30/lakers-owner-jeannie-buss-scores-a-beachfront-condo-in-playa-del-rey"
        target="_blank" alt="WSJ"><img src="/images/press-l-a-t.png"
        alt="LA Times"></a></p>
      <p><a href="https://www.latimes.com/business/real-estate/story/2020-01-30/lakers-owner-jeannie-buss-scores-a-beachfront-condo-in-playa-del-rey"
        target="_blank" alt="WSJ"></a>Meredith Schlosser was <a
        href="https://www.latimes.com/business/real-estate/story/2020-01-30/lakers-owner-jeannie-buss-scores-a-beachfront-condo-in-playa-del-rey"
        target="_&quot;blank&quot;"
        style="font-weight:bold;text-decoration:underline;">featured in the
        Los Angeles Times</a> for helping Lakers President Jeanie Buss find her
        new home.
      </p>
      <hr>
      <br>
      <p>Meredith Schlosser was featured on Million Dollar House Hunters.</p>
      <br>
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/360333933" width="800" height="450"
          frameborder="0" webkitallowfullscreen="" mozallowfullscreen=""
          allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p><a href="https://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-jeanie-buss-playa-vista-20190524-story.html"
        target="_blank" alt="WSJ"><img src="/images/press-l-a-t.png"
        alt="LA Times"></a></p>
      <p><a href="https://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-jeanie-buss-playa-vista-20190524-story.html"
        target="_blank" alt="WSJ"></a>Meredith Schlosser was <a
        href="https://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-jeanie-buss-playa-vista-20190524-story.html"
        target="_&quot;blank&quot;"
        style="font-weight:bold;text-decoration:underline;">featured in the
        Los Angeles Times</a> for selling the home of Lakers President Jeanie
        Buss.
      </p>
      <hr>
      <br>
      <p><a href="https://www.mansionglobal.com/articles/a-three-floor-playa-vista-home-with-ties-to-the-los-angeles-lakers-125104"
        target="_blank" alt="WSJ"><img src="/images/press-mg.png" alt="dujour"
        width="325"></a>
      </p>
      <p><a href="https://www.mansionglobal.com/articles/a-three-floor-playa-vista-home-with-ties-to-the-los-angeles-lakers-125104"
        target="_blank" alt="WSJ"></a>Meredith Schlosser was <a
        href="https://www.mansionglobal.com/articles/a-three-floor-playa-vista-home-with-ties-to-the-los-angeles-lakers-125104"
        target="_&quot;blank&quot;"
        style="font-weight:bold;text-decoration:underline;">featured in
        Mansion Global</a>.
      </p>
      <hr>
      <br>
      <p><a href="http://dujour.com/gallery/tour-a-2-59-million-playa-vista-home-lakers-owner/"
        target="_blank" alt="WSJ"><img src="/images/press-duj.png" alt="dujour"
        width="195"></a>
      </p>
      <p><a href="http://dujour.com/gallery/tour-a-2-59-million-playa-vista-home-lakers-owner/"
        target="_blank" alt="WSJ"></a>Meredith Schlosser was <a
        href="http://dujour.com/gallery/tour-a-2-59-million-playa-vista-home-lakers-owner/"
        target="_&quot;blank&quot;"
        style="font-weight:bold;text-decoration:underline;">featured in
        Dujour</a> for listing a home purchased by Los Angeles Lakers owner
        Jeanie Buss.
      </p>
      <hr>
      <br>
      <p><a href="https://www.wsj.com/articles/l-a-s-new-playa-vista-neighborhood-is-where-silicon-valley-meets-southern-california-1523541600?shareToken=st2db946b244034ce7931b9b5bf21f0038&amp;reflink=article_email_share"
        target="_blank" alt="WSJ"><img src="/images/w-s-j.png" alt="WSJ"></a>
      </p>
      <p><a href="https://www.wsj.com/articles/l-a-s-new-playa-vista-neighborhood-is-where-silicon-valley-meets-southern-california-1523541600?shareToken=st2db946b244034ce7931b9b5bf21f0038&amp;reflink=article_email_share"
        target="_blank" alt="WSJ"></a>Meredith Schlosser was <a
        href="https://www.wsj.com/articles/l-a-s-new-playa-vista-neighborhood-is-where-silicon-valley-meets-southern-california-1523541600?shareToken=st2db946b244034ce7931b9b5bf21f0038&amp;reflink=article_email_share"
        target="_&quot;blank&quot;"
        style="font-weight:bold;text-decoration:underline;">featured in the
        WSJ</a> discussing the Real Estate market in Playa Vista.
      </p>
      <hr>
      <br>
      <p><a href="https://digital.modernluxury.com/publication/?i=570509&amp;ver=html5&amp;p=54#{%22page%22:%2254%22,%22issue_id%22:570509}" target="_blank" alt="LA-Confidential"><img src="/images/press-la-confidential.jpg" width="792" height="558" alt="LA-Confidential"></a><br><br>
        <a href="https://issuu.com/siliconbeachmag/docs/silicon_beach_magazine_march_17_2c_" target="_blank" alt="SiliconBeachMagazine"></a>Meredith Schlosser is featured in <a href="https://digital.modernluxury.com/publication/?i=570509&amp;ver=html5&amp;p=54#{%22page%22:%2254%22,%22issue_id%22:570509}" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">LA Confidential</a> discussing Playa Vista.
      </p>
      <hr>
      <br>
      <p><a href="https://www.mansionglobal.com/articles/actor-kellan-lutz-lists-contemporary-los-angeles-home-206036" target="_blank" alt="WSJ"><img src="/images/press-mg.png" alt="dujour" width="325"></a></p>
      <p><a href="https://www.mansionglobal.com/articles/actor-kellan-lutz-lists-contemporary-los-angeles-home-206036" target="_blank" alt="WSJ"></a>Meredith Schlosser was <a href="https://www.mansionglobal.com/articles/actor-kellan-lutz-lists-contemporary-los-angeles-home-206036" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">featured in Mansion Global</a> for listing the home of actor Kellan Lutz in Playa Vista.</p>
      <hr>
      <br>	
      <p><a href="http://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-kris-humphries-bird-streets-20181025-story.html" target="_blank" alt="WSJ"><img src="/images/press-l-a-t.png" alt="LA Times"></a></p>
      <p><a href="http://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-kris-humphries-bird-streets-20181025-story.html" target="_blank" alt="WSJ"></a>Meredith Schlosser was <a href="http://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-kris-humphries-bird-streets-20181025-story.html" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">featured in the Los Angeles Times</a> for a recent sale in The Hollywood Hills.</p>
      <hr>
      <br>
      <p><a href="https://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-jeanie-buss-playa-vista-20171220-story.html" target="_blank" alt="WSJ"><img src="/images/press-l-a-t.png" alt="LA Times"></a></p>
      <p><a href="https://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-jeanie-buss-playa-vista-20171220-story.html" target="_blank" alt="WSJ"></a>Meredith Schlosser <a href="https://www.latimes.com/business/realestate/hot-property/la-fi-hotprop-jeanie-buss-playa-vista-20171220-story.html" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">featured in the Los Angeles Times</a> for a sale in Playa Vista.</p>
      <hr>
      <br>
      <p>Meredith was featured on <a href="https://youtu.be/giSLkJsJGu4" target="_blank" alt="The Mortgage Voice">The Mortgage Voice</a><a> radio show with Jeff Barton. The interview starts at 32 minutes. </a></p>
      <a> 
      </a>
      <p><a></a><a href="https://youtu.be/giSLkJsJGu4" target="_blank" alt="The Mortgage Voice"><img src="/images/press-mortgage-voice.jpg" width="1020px" height="574px" alt="The Mortgage Voice"></a><br><br>
      </p>
      <hr>
      <br>
      <p>Meredith was featured on The Mortgage Voice radio show with Jeff Barton.</p>
      <div class="video-container">
        <iframe src="https://youtube.com/embed/unvHKI_Mnb0?start=655" width="800" height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p><a href="https://issuu.com/siliconbeachmag/docs/silicon_beach_magazine_march_17_2c_" target="_blank" alt="SiliconBeachMagazine"><img src="/images/silicon-beach-magazine-2.jpg" alt="SiliconBeachMagazine"></a><br><br>
        <a href="https://issuu.com/siliconbeachmag/docs/silicon_beach_magazine_march_17_2c_" target="_blank" alt="SiliconBeachMagazine"></a>Meredith Schlosser Featured on pages 23-28 of <a href="https://issuu.com/siliconbeachmag/docs/silicon_beach_magazine_march_17_2c_" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">Silicon Beach Magazine.</a>!
      </p>
      <hr>
      <br>
      <p>Meredith was one of three agents chosen to speak about her success in Real Estate. This event was held at the Skirball Center in September 2017 and was moderated by Steve Shull.</p>
      <div class="video-container">
        <iframe src="https://youtube.com/embed/4zyeRy3IWQU?start=9537" width="800" height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p>Meredith Schlosser was asked to speak at her alma mater, American University. She shared her story in front of students from the School of Communication about her journey and success in real estate.</p>
      <p><img src="/images/press-layout-2.jpg" alt="American University panel">
      </p>
      <hr>
      <p><a href="http://www.businessinsider.com/cointreau-descendant-lists-malibu-vineyard-for-12-million-2017-8/" target="_blank" alt="BusinessInsider"><img src="/images/press-business-insider.jpg" alt="SiliconBeachMagazine"></a><br><br>
        <a href="http://www.businessinsider.com/cointreau-descendant-lists-malibu-vineyard-for-12-million-2017-8/" target="_blank" alt="BusinessInsider"></a><a href="http://www.businessinsider.com/cointreau-descendant-lists-malibu-vineyard-for-12-million-2017-8/" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">Business Insider</a> features Meredith's listing in Malibu – 5941 Kanan Dume Road! 
      </p>
      <hr>
      <br>
      <p><a href="https://therealdeal.com/la/2017/08/18/cointreau-heiress-lists-malibu-home-for-12-5m/" target="_blank" alt="TheRealDeal"><img src="/images/real-deal.jpg" alt="SiliconBeachMagazine"></a><br><br>
        <a href="https://therealdeal.com/la/2017/08/18/cointreau-heiress-lists-malibu-home-for-12-5m/" target="_blank" alt="TheRealDeal"></a>Meredith's listing at 5941 Kanan Dume Rd in Malibu is featured in <a href="https://therealdeal.com/la/2017/08/18/cointreau-heiress-lists-malibu-home-for-12-5m/" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">The Real Deal.</a>!
      </p>
      <hr>
      <br>
      <p><a href="/images/Top-Agent-Magazine-Meredith-Schlosser.pdf" target="_blank" alt="TopAgentMagazine"><img src="/images/Top-Agent-Magazine.jpg" alt="TopAgentMagazine"></a><br><br>
        <a href="/images/Top-Agent-Magazine-Meredith-Schlosser.pdf" target="_blank" alt="TopAgentMagazine"></a>Meredith Schlosser Featured in <a href="/images/Top-Agent-Magazine-Meredith-Schlosser.pdf" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">Top Agent Magazine.</a>!
      </p>
      <hr>
      <br>
      <p>Meredith Schlosser was featured on a panel representing the John Aaroe Group Beverly Hills office to speak about her success in Real Estate. There were only 6 agents chosen out of 410 agents company wide.</p>
      <p><img src="/images/press-layout.jpg" alt="John Aaroe Group Beverly Hills panel">
      </p>
      <hr>
      <br>
      <p><a href="http://variety.com/2016/dirt/real-estalker/frederick-huntsberry-sets-record-in-cheviot-hills-1201681051/" target="_blank" alt="Variety"><img src="/images/variety.png" alt="Variety"></a><br><br>
        <a href="http://variety.com/2016/dirt/real-estalker/frederick-huntsberry-sets-record-in-cheviot-hills-1201681051/" target="_blank" alt="Variety"></a>Sally Forster Jones Team sets the <a href="http://variety.com/2016/dirt/real-estalker/frederick-huntsberry-sets-record-in-cheviot-hills-1201681051/" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">record for the highest priced sale ever</a> in Cheviot Hills!
      </p>
      <hr>
      <br>
      <p><a href="http://realtrends.com" target="_blank" alt="RealTrends"><img src="/images/realtrends.png" alt="Realtrends"></a><br><br>
        <a href="http://realtrends.com" target="_blank" alt="RealTrends">RealTrends</a> <a href="http://www.realtrends.com/rankings/real-trends-1000-teams-by-volume15" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">published an article</a> that ranks the Sally Forster Jones Group as #2 in total transactions volume in Los Angeles and #9 in the United States.
      </p>
      <hr>
      <p>Meredith Schlosser was featured on a top agent panel with other agents who had tripled their business in the last year.</p>
      <br>
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/117564884" width="800" height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <br><br>
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/117564885" width="800" height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
      <hr>
      <br>
      <p>
        <a href="http://robbreport.com/shelter/homes-for-sale/wine-estates-and-vineyards-in-argentina-and-america-2776660/" target="_blank" alt="BusinessInsider"><img src="/images/press-robb-report.jpg" alt="RobbReport"></a><br><br>
        <a href="http://robbreport.com/shelter/homes-for-sale/wine-estates-and-vineyards-in-argentina-and-america-2776660/" target="_blank" alt="BusinessInsider"></a><a href="http://robbreport.com/shelter/homes-for-sale/wine-estates-and-vineyards-in-argentina-and-america-2776660/" target="_&quot;blank&quot;" style="font-weight:bold;text-decoration:underline;">Robb Report</a> features Meredith's listing in Malibu – 5941 Kanan Dume Road! 
      </p>
    </div>
  </article>
</div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "Press",
    components: {
        PageTitle
    },
    metaInfo: {
        title: 'Press, News, and Video of Realtor Meredith Schlosser',
        meta: [{
            name: 'description', 
            content: "Meredith Schlosser's Press, News, and Videos page."
        },
        {
            name: 'author', 
            content: "Meredith Schlosser"
        },]
    },
}
</script>

<style lang="scss" scoped>
.pdf-image{
  width: 420px;
}

.image-style-1{
  height: 205px;
}

@media (max-width: $mobile) { 
  .pdf-image{
    width: 280px;
  }
  .image-style-1{
    width: 280px;
    height: auto;
  }
}
</style>